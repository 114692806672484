import React, { useEffect } from "react";
import { Provider } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";

import store from "../store";
import Header from "../components/common/Header";
import MobileMainNav from "../components/common/mobile-menu";
import Footer from "../components/common/footer";

import QuickView from "../components/features/product/common/quickview";
import ConsentBanner from "../components/common/ConsentBanner";
import { scrollTop } from "../utils/index";
import { ToastContainer } from "react-toastify";
import {useStaticQuery, graphql} from 'gatsby'
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID ,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const MainLayout = ({ children }) => {

  const pages = useStaticQuery(graphql`
  query pagesQuery {
   
      allPages {
      nodes {
        title
        slug
        location {
          header
          footer
          sidebar
        }
      }
    
    }
  }
`)
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Provider store={store}>
          <QuickView />
      <InstantSearch
        searchClient={searchClient}
        indexName="Jimenez_MR_Discount"
      >
        {" "}
        <div className="mobile-menu-overlay"></div>
        <button id="scroll-top" title="Back to Top">
          <i className="icon-arrow-up"></i>
        </button>
        {/* <div className="page-wrapper"> */}
        <Header pages={pages} />
        {children}
        <Footer pages={pages} />
        <ConsentBanner />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={true}
          toastClassName="toast-custom-style"
        />
        <MobileMainNav />
        {/* </div> */}
    
      </InstantSearch>
    </Provider>
  );
};

export default MainLayout;
