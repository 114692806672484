import { combineReducers } from "redux";

import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
// Import custom components
import productReducer from "./products";
import cartReducer from "./cart";
import filterReducer from "./filter";
import modalReducer from "./modal";
import userReducer from "./user";

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,

};

const rootReducer = combineReducers({
  data: productReducer,
  cartlist: cartReducer,
  filters: filterReducer,
  modal: modalReducer,
  user: userReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
