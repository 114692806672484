import React from "react";
import { Link } from "gatsby";

import { mobileMenu } from "../../../utils";

function MobileMainNav(props) {

  React.useEffect(() => {
    mobileMenu();
  });

  return (
    <nav className="mobile-nav">
      <ul className="mobile-menu">
        <li>
          <Link to={`/`}>Home</Link>

          {/* <ul>

                    

                    </ul> */}
        </li>

        <li>
          <Link to={`/faqs`}>FAQS</Link>
        </li>
        <li>
          <Link to={`/terms`}>Terms and Conditions</Link>
        </li>
        <li>
          <Link to={`/privacy`}>Privacy Policy</Link>
        </li>
        <li>
          <Link to={`/contact`}>Contact</Link>
        </li>
        <li>
          {/* className="sf-with-ul" */}

          <Link to={`/aboutus`}>About</Link>

          {/* <ul >
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/default/7` }>Default</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/centered/9` }>Centered</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/extended/8` }><span>Extended Info<span className="tip tip-new">New</span></span></Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/gallery/1` }>Gallery</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/sticky/6` }>Sticky Info</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/sidebar/5` }>Boxed With Sidebar</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/fullwidth/2` }>Full Width</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/masonry/4` }>Masonry Sticky Info</Link></li>
                        </ul> */}
        </li>
      </ul>
    </nav>
  );
}

export default MobileMainNav;
