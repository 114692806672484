import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

import { Link, StaticQuery, graphql } from "gatsby";
import slugify from "slugify";
import Logo from "../common/logo";
import MobileMainNav from "./partials/mobile-nav";

function MobileMenu(props) {
  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-wrapper">
        <span className="mobile-menu-close">
          <i className="icon-close"></i>
        </span>

        <div style={{ padding: "0px 10px 0px 10px" }}>
          <Logo />
        </div>
        <Tabs defaultIndex={1} selectedTabClassName="show">
          <TabList
            className="nav nav-pills-mobile nav-border-anim"
            role="tablist"
          >
            <Tab className="nav-item">
              <span className="nav-link">Menu</span>
            </Tab>

            <Tab className="nav-item">
              <span className="nav-link">Categories</span>
            </Tab>
          </TabList>

          <div className="tab-content">
            <TabPanel>
              <MobileMainNav />
            </TabPanel>

            <TabPanel>
              <nav className="mobile-nav">
                <ul className="mobile-menu">
                  <li className="item-lead">
                    <Link to="/collections" className="mobile-cats-lead">
                      All Categories
                    </Link>
                  </li>
                  <li className="mobile-cats-lead">
                    <Link to="/allcollection">All Products</Link>
                  </li>

                  <StaticQuery
                    query={query}
                    render={({ allCategories }) => {
                      let categories = allCategories.nodes.filter(
                        (c) => c._id !== "1"
                      );
                      return categories.map(
                        ({ name, itemCount, subCategories }, i) => {
                          const path = `/collections/${slugify(
                            name
                          ).toLowerCase()}/`;

                          return (
                            <li>
                              <Link key={`cat${i}`} to={path}>
                                {name} ({itemCount}){" "}
                                <span className="mmenu-btn"></span>
                              </Link>
                              <ul>
                                {subCategories.length > 0 &&
                                  subCategories.map((subcat, j) => {
                                    const subpath = `${path}${slugify(
                                      subcat.name
                                    ).toLowerCase()}/`;
                                    return (
                                      <li key={`subcat${j}`}>
                                        <Link to={subpath}>{subcat.name}</Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          );
                        }
                      );
                    }}
                  />
                </ul>
              </nav>
            </TabPanel>
          </div>
        </Tabs>

        <div className="social-icons">
          <Link to="#" className="social-icon" target="_blank" title="Facebook">
            <i className="icon-facebook-f"></i>
          </Link>
          <Link to="#" className="social-icon" target="_blank" title="Twitter">
            <i className="icon-twitter"></i>
          </Link>
          <Link
            to="#"
            className="social-icon"
            target="_blank"
            title="Instagram"
          >
            <i className="icon-instagram"></i>
          </Link>
          <Link to="#" className="social-icon" target="_blank" title="Youtube">
            <i className="icon-youtube"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default React.memo(MobileMenu);

const query = graphql`
  {
      allCategories {
        nodes{
          _id
        name
        seoMetaData{
          slug
          title
          description
        }
        itemCount
        subCategories {
          name
          seoMetaData{
            slug
            title
            description
          }
        }
        }
      
    }
  }
`;