import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { GatsbyImage } from "gatsby-plugin-image";
import imagesLoaded from "imagesloaded";
import slugify from "slugify";

import { addToCart } from "../../../../reducers/cart";
import { closeQuickViewModal } from "../../../../actions";
import { quantityInputs, safeContent, productGallery } from "../../../../utils";

const customStyles = {
  content: {
    top: "30%",
    transform: "translateY(-50%)",
    maxHeight: "400px",
  },
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "10000",
  },
};

Modal.setAppElement("#___gatsby");

function QuickView(props) {
  // showModal
  const { showModal, productDetail, addToCart, closeQuickViewModal } = props;

  const [stock] = useState(props.productDetail?.quantity);
  //   const [quantity] = useState(1);

  const addToCartHandler = () => {
    // console.log(document.querySelector("#qty-quickview").value);
    if (0 !== stock)
      addToCart({
        ...productDetail,
        quantity: parseInt(document.querySelector("#qty-quickview").value),
        stock: stock,
      });
  };
  React.useEffect(() => {
    productGallery();
    // closeQuickViewModal();
  });

  function afterOpenModal() {
    quantityInputs();
    let imgLoad = imagesLoaded(".product-lg", { background: true });

    imgLoad.on("done", function (instance, image) {
      document
        .querySelector(".skel-pro-single.skel-quickview")
        .classList.add("loaded");
    });
  }

  const closeQuickViewModalHandler = () => {
    closeQuickViewModal();
  };

  let ratings = productDetail?.reviews * 20;

  return productDetail && (
    <Modal
      isOpen={showModal}
      contentLabel="QuickView"
      onAfterOpen={afterOpenModal}
      onRequestClose={closeQuickViewModal}
      shouldFocusAfterRender={false}
      style={customStyles}
      className="container quickView-container"
      id="product-quickview"
    >
      <div className="modal-content" style={{ maxHeight: "400px" }}>
        <div className="quickView-content horizontal skeleton-body ">
          <div className="quickView-content horizontal skeleton-body ">
            <div className="row skel-pro-single skel-quickview loaded">
              <div className="col-lg-6 p-0 pr-lg-2 mb-2 mb-lg-0">
                <div className="skel-product-gallery"></div>

                <div className="product-lg mb-1">
                  <GatsbyImage
                    image={productDetail.image.childImageSharp.gatsbyImageData}
                    alt="product"
                    className="img-fluid" />
                  {/* <OwlCarousel
                            adClass="owl-quickview cols-1"
                            carouselOptions="{dots: false, nav: false, loop: true, autoPlay: false, dotsContainer: #owl-dots}"
                            id="owl_quickview"
                            carouselId="quickView"
                        >
                            {
                                images.map( ( item, index ) => (
                                    <div className="intro-slide bg-transparent" key={ "gallery" + index }>
                                        <img src={ process.env.PUBLIC_URL + '/' + item } alt="Desc" />
                                        <button className="btn-fullscreen" onClick={ () => openLightBox( index ) }>
                                            <i className="icon-arrows"></i>
                                        </button>
                                    </div>
                                ) )
                            }
                        </OwlCarousel> */}
                </div>

                {/* <div className="product-sm row" id="owl-dots">
                        {
                            smallPictures.map( ( item, index ) => (
                                <Link onClick={ preventNav } to="#" className={ `carousel-dot d-block ${0 === index ? 'active' : ''}` } key={ "product-dot-" + index }>
                                    <img src={ process.env.PUBLIC_URL + '/' + item } alt="dot" />
                                </Link>
                            ) )
                        }
                    </div> */}
              </div>

              <div
                style={{ overflowY: "auto !important" }}
                className="col-lg-6 quickview-desc pl-0 pl-lg-4 pr-0"
              >
                <div className="entry-summary row ">
                  <div className="col-md-12">
                    <div className="entry-summary1"></div>
                  </div>

                  <div className="col-md-12">
                    <div className="entry-summary2"></div>
                  </div>
                </div>

                <div className="product-real-detail product-details">
                  <h2
                    className="product-title text-dark"
                    dangerouslySetInnerHTML={safeContent(productDetail.title)}
                  ></h2>
                  <div className="ratings-container">
                    <div className="ratings">
                      <div
                        className="ratings-val"
                        style={{ width: ratings + "%" }}
                      ></div>
                    </div>
                    <span className="ratings-text">
                      ({productDetail.rating?.toFixed(2) ?? 0} Reviews )
                    </span>
                  </div>
                  <div className="row w-100 align-items-center d-block">
                    <div className="product-cat mb-1">
                      <span>Availablity:</span>
                      <span>{0 !== stock ? "InStock" : "Out of Stock"}</span>
                    </div>

                    <div className="product-cat d-flex flex-wrap">
                      <span className="mb-1">Condition:</span>
                      <span className="mb-1">{productDetail.status}</span>
                    </div>
                  </div>


                  <div
                    className="product-content"
                    style={{ overflowY: "auto !important" }}
                  >
                    {" "}
                    <p
                      className="product-txt"
                      dangerouslySetInnerHTML={{
                        __html: productDetail.description,
                      }}
                    ></p>
                  </div>

                  {/* { productDetail.variants ?
                            ( productDetail.variants[ 0 ].image || productDetail.variants[ 0 ].model ) ?
                                <div className="details-filter-row product-nav product-nav-thumbs">
                                    { productDetail.variants.map( ( vari, index ) => {
                                        return (
                                            <Link to="#" key={ "quickview-img-" + index } className={ 0 === index ? 'active' : '' }>
                                                <LazyLoadImage
                                                    alt="product"
                                                    src={ process.env.PUBLIC_URL + '/' + ( vari.image ? vari.image : vari.model ? vari.model : '' ) }
                                                    effect="blur"
                                                    className="skeletion-container"
                                                    visibleByDefault={ true }
                                                />
                                            </Link> )
                                    } ) }
                                </div> :

                                <div className="details-filter-row product-nav product-nav-dots">
                                    { productDetail.variants.map( ( vari, i ) =>
                                        <button
                                            className={ 0 === i ? 'active' : '' }
                                            style={ { backgroundColor: vari.color } }
                                            data-image={ vari }
                                            // data-zoom-image={ vari.bigImages[ i ] }
                                            key={ productDetail.id + '-' + i }
                                        >
                                        </button>
                                    ) }
                                </div> : ''
                        } */}

                  <div className="details-filter-row details-row-size">
                    <label htmlFor="qty">Qty:</label>
                    <div className="product-details-quantity">
                      <input
                        type="number"
                        id="qty-quickview"
                        className="form-control"
                        defaultValue="1"
                        min="1"
                        max={stock}
                        step="1"
                        data-decimals="0"
                        required
                      />
                    </div>
                  </div>

                  <div className="product-details-action mt-0 mt-sm-3">
                    <div className="details-action-wrapper">
                      <div className="col-sm-6 pl-0">
                        <button
                          className="btn-product btn-cart btn-cart__color w-100"
                          onClick={addToCartHandler}
                          style={{ minHeight: "44px" }}
                        >
                          <span>add to cart</span>
                        </button>
                      </div>{" "}
                      <div className="col-sm-6 pl-0">
                        <Link
                          className="btn-product btn-cart btn-cart__color w-100"
                          to={`/products/${slugify(productDetail.title)}`}
                          style={{ minHeight: "44px" }}
                        >
                          <span>View Details</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        title="Close (Esc)"
        type="button"
        className="mfp-close"
        onClick={closeQuickViewModalHandler}
      >
        <span>×</span>
      </button>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    showModal: state.data.quickView,
    productDetail: state.data.productDetail,
  };
};
const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
  closeQuickViewModal: () => dispatch(closeQuickViewModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickView);
