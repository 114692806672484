import React from "react";
import { Link,  } from "gatsby";
import Logo from "./logo";

function Footer(props) {
  const { container = "container" } = props;
 
  const handleAddress=(e)=> {
    const addr =
      "maps.google.com/maps?q=6425+English+Ave,+Indianapolis,+IN+46219,+USA"

    if (navigator.platform.match(/iPhone|iPad|iPod/i))
      window.open(`maps://${addr}`)
    else window.open(`https://${addr}`)

    e.preventDefault()
  }
  return (
    <footer className="footer">
      <div className="footer-middle">
        <div className={container}>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="widget widget-about">
                <Logo />
                <p>
                  Our goal is to make home remodeling affordable for everyone.{" "}
                </p>

                {/* <div className="widget-call">
                  <i className="icon-phone"></i>
                  Got Question? Call us 24/7
                  <a href="tel:812-562-5622">812-562-5622</a>
                </div> */}
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Useful Links</h4>

                <ul className="widget-list">
                 
                  <li>
                    <Link to={`/aboutus`}>About Us</Link>
                  </li>
                  <li>
                    <Link to={`/faqs`}>FAQ</Link>
                  </li>
                  <li>
                    <Link to={`/contact`}>Contact us</Link>
                  </li>
                  {
                    props.pages.allPages.nodes.map((page, index) => {
                      return (
                       page?.location?.footer &&  <li key={index}>
                       <Link to={`/${page.slug}`}>{page.title}</Link>
                     </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Customer Service</h4>

                <ul className="widget-list">
                  <li>
                    <Link to="/wholesale">Wholesale</Link>
                  </li>
                  <li>
                    <Link to={`/terms`}>Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Contact Us</h4>

                <ul className="widget-list">
                  <li>
                    <i className="icon-phone mr-2"></i>{" "}
                    <a href="tel:812-562-5622">812-562-5622</a>
                  </li>
                  <li>
                    <i className="icon-home mr-2"></i>
                    <a href="/"  onClick={handleAddress}>6425 English Ave, Indianapolis, IN 46219</a>
                  </li>
                  <li>
                    {" "}
                    <i className="icon-mail-reply mr-2"></i>
                    <a href="mailto:contact@jimenezmrdiscount.com">
                    contact@jimenezmrdiscount.com
                    </a>
                  </li>
                  <li>Store hours:</li>
                  <li>
                    Monday - Friday 9am - 6pm
                    <br />
                    Saturday 9am - 5pm
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className={container}>
          <p className="footer-copyright">
            Copyright © {new Date().getFullYear()} Jimenez. All Rights Reserved.
            Designed and Developed by{" "}
            <a href="https://www.accelerone.com">Accelerone</a>
          </p>
          <figure className="footer-payments">
            <img
              loading="lazy"
              src={`/assets/images/payments.png`}
              alt="Payment methods"
              width="272"
              height="20"
            />
          </figure>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
