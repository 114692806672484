import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

/*
 * ------------------------------------
 * ACTIONS TYPES
 * ------------------------------------
 */

const ADD_TO_CART = "ADD_TO_CART";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
const INCREMENT_QTY = "INCREMENT_QTY";
const DECREMENT_QTY = "DECREMENT_QTY";

const SET_ORDER = "SET_ORDER";
const SET_PROGRESS = "SET_PROGRESS";
const SET_CART = "SET_CART";
const RESTORE_CART = "RESTORE_CART";
const SET_DISCOUNT = "SET_DISCOUNT";
const RESET_DISCOUNT = "RESET_DISCOUNT";
const RESET_CART = "RESET_CART";
const UPDATE_CART = "UPDATE_CART";
/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: { item },
});

export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  payload: { itemId: id },
});

export const incrementQty = (id) => ({
  type: INCREMENT_QTY,
  payload: { itemId: id },
});

export const decrementQty = (id) => ({
  type: DECREMENT_QTY,
  payload: { itemId: id },
});

export const setOrder = (order) => ({ type: SET_ORDER, payload: { order } });
export const setProgress = (value) => ({
  type: SET_PROGRESS,
  payload: { value },
});
export const setCart = (cart) => ({ type: SET_CART, payload: { cart } });
export const restoreCart = (cart) => ({
  type: RESTORE_CART,
  payload: { cart },
});
export const setDiscount = (discount) => ({
  type: SET_DISCOUNT,
  payload: { discount },
});
export const resetDiscount = () => ({ type: RESET_DISCOUNT });
export const resetCart = () => ({ type: RESET_CART });
export const updateCart = (cart) => ({ type: UPDATE_CART, payload: { cart } });
/*
 * ------------------------------------
 * REDUCERS
 * ------------------------------------
 */

const initState = {
  items: [],
  order: {
    orderId: null,
    orderNumber: null,
    customer: null,
    type:'',
    tax: 0,
  },
  discount: { type: null, code: "", amount: 0 },
  progress: "info", // possible values: 'info', 'payment', 'success',
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TO_CART: {
      const { item } = payload;
      const { _id, quantity, stock } = item;
      let newItems = [];
      const isDuplicate = state.items.some((itm) => itm._id === _id);
      if (isDuplicate) {
        newItems = state.items.map((itm) => {
          if (itm._id === _id) {
            if (stock >= itm.quantity + quantity) {
              toast.info("Item Qty Incremented to Cart");
              return { ...itm, quantity: itm.quantity + quantity };
            } else {
              toast.error("Sorry, we do not have enough stock.");
            }
          }
          return itm;
        });
      } else {
        newItems = state.items.concat(item);
        toast.success("Item Added to the Cart");
      }

      return { ...state, items: newItems };
    }

    case REMOVE_FROM_CART: {
      const { itemId } = payload;

      const items = state.items.filter((item) => item._id !== itemId);

      toast.error("Item Removed from the Cart");
      return { ...state, items };
    }

    case INCREMENT_QTY: {
      const { itemId } = payload;
      const newItems = state.items.map((item) => {
        if (item._id === itemId) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      return { ...state, items: newItems };
    }

    case DECREMENT_QTY: {
      const { itemId } = payload;
      const items = state.items.map((item) => {
        if (item._id === itemId) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
      return { ...state, items };
    }
    case UPDATE_CART: {
      return Object.assign({}, { ...state, items: payload.cart });
    }
    case SET_ORDER: {
      const { order } = payload;
      return { ...state, order };
    }

    case SET_PROGRESS: {
      const { value } = payload;
      return { ...state, progress: value };
    }

    case SET_CART: {
      const { cart } = payload;

      return { ...cart };
    }
    case RESTORE_CART: {
      const { cart } = payload;
      return { ...state, items: cart };
    }
    case SET_DISCOUNT: {
      const { discount } = payload;
      return { ...state, discount };
    }

    case RESET_DISCOUNT:
      return { ...state, discount: initState.discount };

    case RESET_CART: {
      return { ...initState };
    }

    default:
      return state;
  }
}

/*
 * ------------------------------------
 * SELECTORS
 * ------------------------------------
 */

export const getCartItems = (state) => state.cartlist.items;
export const getOrder = (state) => state.cartlist.order;
export const getDiscount = (state) => state.cartlist.discount;
export const getProgress = (state) => state.cartlist.progress;
