
import { axiosIns } from '../apihelper';

/*
 * ------------------------------------
 * ACTIONS
 * ------------------------------------
 */
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN_PROVIDER = "USER_LOGIN_PROVIDER";
export const loginByProvider = (data) => ({
  type: USER_LOGIN_PROVIDER,
  payload: { data },
});
export const UPDATE_USER = "UPDATE_USER";
export const updateUserAction = (data) => ({
  type: UPDATE_USER,
  payload: { data },
});
export const UPLOADING_IN_PROGRESS = "PRODUCTS_UPLOADING_IN_PROGRESS";
export const uploadingInProgress = () => ({
  type: UPLOADING_IN_PROGRESS,
});

export const UPLOADING_SUCCESS = "PRODUCTS_UPLOADING_SUCCESS";
export const uploadingSuccess = () => ({
  type: UPLOADING_SUCCESS,
});

export const UPLOADING_FAILURE = "PRODUCTS_UPLOADING_FAILURE";
export const uploadingFailure = (data) => ({
  type: UPLOADING_FAILURE,
  payload:{data}
});
export const UPLOADING_INIT = "UPLOADING_INIT";
export const uploadInit = () => ({
  type: UPLOADING_INIT,
});

const initialState = {
  isAuthenticated: false,
  data: {},
  error: {},
  isUploading: false,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN: {
      const { data } = payload;
      if (
        data.status === "pending" ||
        data.status === "waiting" ||
        data.status === "inactive"
      )
        return {
          ...state,
          data,
          isAuthenticated: false,
          isVerified: data.status,
          isUploading: false,
        };
      return {
        ...state,
        data,
        isAuthenticated: true,
        isVerified: data.status,
      };
    }
    case UPDATE_USER: {
      const { data } = payload;
      const newData = { ...state.data, ...data };
      return { ...state, data: newData };
    }
    case USER_LOGOUT: {
      const email = state.data.email;
      return {
        ...state,
        data: { email },
        isAuthenticated: false,
        isVerified: "login",
      };
    }
    case UPLOADING_SUCCESS: {
      return { ...state, isUploading: false, status: "success", error:{} };
    }
    case UPLOADING_IN_PROGRESS:
      return { ...state, isUploading: true, status: "progress", error:{}};
    case UPLOADING_INIT:
      return { ...state, status: "init", error:{} };
    case UPLOADING_FAILURE:
{ 
  const { data } = payload;
  return { ...state, isUploading: false, status: "failed", error:data };}

    default:
      return state;
  }
}

/*
 * ------------------------------------
 * THUNKS
 * ------------------------------------
 */

export const login = (data) => (dispatch, getState) => {
  
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};


const headers = REQUEST_HEADERS;
    return axiosIns
      .post('/marketplaceLogin', data, { headers })
      .then((res) => dispatch({ type: USER_LOGIN, payload: { data: res.data } }));
  };
  export const fetchUser = (data) => (dispatch, getState) => {
    axiosIns.defaults.headers.common['Authorization'] = `Bearer ${data}`;
    return axiosIns
      .get('/users')
      .then((res) => dispatch({ type: USER_LOGIN, payload: { data: res.data } }));
  };
  export const updateUser = (data) => async (dispatch, getState) => {
    dispatch(uploadingInProgress());
    try {

      const state = getState();
      const token = state.user.data.token;
      axiosIns.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const newdata = await axiosIns.put(`/users/marketplaceuser`, data);
  
      dispatch(updateUserAction(newdata.data));
      dispatch(uploadingSuccess());
    } catch (err) {
      dispatch(uploadingFailure(err.response.data));
      console.log(err);
    }
  };
  
 
  
  export const logout = () => (dispatch, getState) => {
    try {
      dispatch({ type: USER_LOGOUT, payload: {} });
    } catch (err) {
      console.log(err);
    }
  };
  
 
  
  /*
   * ------------------------------------
   * SELECTORS
   * ------------------------------------
   */
  export const getUser = (state) => state.user.data;
  export const getAccessToken = (state) => state.user.data.token;
  export const getIsAuthenticated = (state) => state.user.isAuthenticated;
  export const getVerificationStatus = (state) => state.user.isVerified;
  
  export const getIsUploadingUser = (state) => state.user.isUploading;
  export const getUploadStatus = (state) => state.user.status;
  export const getUploadErrors = (state) => state.user.error;
  


export default  userReducer;
