import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, meta, product, category, page, location }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  let { description, author, siteUrl } = site.siteMetadata;
  let schemaOrgJSONLD = null;
  if (product) {
    let { title, price, brand, cl_upc, condition, thumbnail_100 } = product;
    schemaOrgJSONLD = {
      "@context": "https://schema.org",
      "@type": "Product",
      productID: cl_upc,
      name: title,
      description: product.description ?? "",
      url: `${location?.origin}${location?.pathname || "/"}`,
      image:
        location?.origin + "" + thumbnail_100?.local?.childImageSharp?.gatsbyImageData.src,
      brand: brand ?? "",
      offers: [
        {
          "@type": "Offer",
          price: price,
          priceCurrency: "USD",
          itemCondition: `https://schema.org/${
            condition === "New" || condition === "Like-New"
              ? "NewCondition"
              : condition === "Open-Box" || condition === "Used"
              ? "UsedCondition"
              : "RefurbishedCondition"
          }`,
          availability: "https://schema.org/InStock",
        },
      ],
    };
    if (product?.seoMetaData) {
      if (product.seoMetaData?.title) {
        title = product.seoMetaData.title;
      } else {
        title = product.title;
      }
      if (product.seoMetaData?.description) {
        description = product.seoMetaData.description;
      } else if (product.description) {
        description = product.description?.replace(/<[^>]+>/g, " ");
      }
    }
  }
  if (category && category?.seoMetaData) {
    if (category.seoMetaData?.title) {
      title = category.seoMetaData?.title;
    } else {
      title = category.name;
    }
    if (category.seoMetaData?.description) {
      description = category.seoMetaData?.description;
    }
  }
  if (page) {
    if (page.seoMetaData?.title) {
      title = page.seoMetaData?.title;
    } else {
      title = page.title;
    }
    if (page.seoMetaData?.description) {
      description = page.seoMetaData?.description;
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `title`,
          content: title || site.siteMetadata.title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: "/assets/images/miniature.jpg",
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:url`,
          content: siteUrl,
        },
        {
          property: `twitter:creator`,
          content: author,
        },
        {
          property: `twitter:title`,
          content:
            category?.seoMetaData?.title || title || site.siteMetadata.title,
        },
        {
          property: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:image`,
          content: "/assets/images/icon.png",
        },
      ].concat(meta)}
    >
      {" "}
      {product && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      )}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
